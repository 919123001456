.pastEventsCard {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--c-light);
	border-radius: 4px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
	padding: 8px;
	margin: 8px 0;
}
.pastEventsCard .pastEventsCardEmbed {
	width: 100%;
}
.pastEventsCard .pastEventsCardImage {
	width: 100%;
}
.pastEventsCard .pastEventsCardImage img {
	width: 100%;
	border-radius: 4px;
}
.pastEventsCard .pastEventsCardTags {
	display: flex;
	width: 100%;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 2px;
}
.pastEventsCard .pastEventsCardTags .pastEventsCardTag {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--c-primary-dark);
	color: var(--c-light);
	border-radius: 4px;
	padding: 4px 12px;
	margin-right: 4px;
}
.pastEventsCard .pastEventsCardTags .pastEventsCardTag:last-child {
	margin-right: 0;
}
.pastEventsCard .pastEventsCardContent {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-bottom: 8px;
}
.pastEventsCard .pastEventsCardContent .pastEventsCardTitle {
	margin-bottom: 0px;
	font-size: 20px;
	font-weight: 600;
	color: var(--c-primary);
}
