.loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.5);
	z-index: 9999;
}
.loading .card {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	/* width: 300px;
	height: 300px; */
	width: fit-content;
	padding: 16px 32px;
	border-radius: 4px;
	background: var(--c-light);
	/* box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.3); */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.loading .card img {
	width: 50px;
}
.loading .card p {
	font-size: 18px;
	font-weight: 500;
	margin-top: 16px;
}
