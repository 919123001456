.tile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 46%;
	padding: 16px;
	margin: 4px;
	border-radius: 4px;
	background-color: var(--c-light);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
	transition: all 0.2s ease-in-out;
}
.tile .tileIcon {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 16px 0;
	transition: all 0.2s ease-in-out;
}
.tile:hover .tileIcon {
	transform: translateY(-4px);
}
.tile .tileIcon img {
	width: 72px;
}
.tile .tileTitle {
	font-size: 20px;
	font-weight: 600;
}
.tile:hover {
	filter: contrast(120%);
	cursor: pointer;
}

.tile:active {
	filter: contrast(120%);
	transform: scale(0.97);
}
