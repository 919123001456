@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap);
:root {
	--c-primary: #5865f2;
	--c-primary-light: #5865f21a;
	--c-primary-dark: #292841;
	--c-grey: #888888;
	--c-darkGrey: #222222;
	--c-lightGrey: #e0e0e0;
	--c-light: #f6f6f6;
	--c-success: #00e175;
	--c-danger: #ff2525;
	--c-warning: #ff993c;
	--c-disabled: #bbbbbb;
	--c-yellow: #ffbd00;
}
/* Global Styles */
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	scroll-behavior: smooth;
	font-family: 'Poppins', sans-serif;
}
body {
	background: #f6f6f6;
	background: var(--c-light);
	text-transform: lowercase;
	color: #292841;
	color: var(--c-primary-dark);
	-webkit-tap-highlight-color: transparent;
}

a {
	text-decoration: none;
	color: inherit;
}
/* width */
::-webkit-scrollbar {
	width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.link {
	cursor: pointer;
}
.c-primary {
	color: #5865f2;
	color: var(--c-primary);
}
.c-primary-light {
	color: #5865f21a;
	color: var(--c-primary-light);
}
.c-primary-dark {
	color: #292841;
	color: var(--c-primary-dark);
}
.c-grey {
	color: #888888;
	color: var(--c-grey);
}
.c-darkGrey {
	color: #222222;
	color: var(--c-darkGrey);
}
.c-lightGrey {
	color: #e0e0e0;
	color: var(--c-lightGrey);
}
.c-light {
	color: #f6f6f6;
	color: var(--c-light);
}
.c-disabled {
	color: #bbbbbb;
	color: var(--c-disabled);
}
.c-success {
	color: #00e175;
	color: var(--c-success);
}
.c-danger {
	color: #ff2525;
	color: var(--c-danger);
}
.c-warning {
	color: #ff993c;
	color: var(--c-warning);
}
.c-yellow {
	color: #ffbd00;
	color: var(--c-yellow);
}

.rowCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}
.colCenter {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

/* Mobile Global CSS */
body {
	padding: 80px 20px 0px 20px;
	overflow-x: hidden;
}

.headingL {
	font-size: 48px;
	font-weight: 800;
}
.headingM {
	font-size: 32px;
	font-weight: 700;
}
.headingS {
	font-size: 24px;
	font-weight: 700;
}
.contentXL {
	font-size: 22px;
	font-weight: 600;
}
.contentL {
	font-size: 20px;
	font-weight: 600;
}
.content {
	font-size: 18px;
	font-weight: 500;
}
.contentM {
	font-size: 16px;
	font-weight: 600;
}
.contentS {
	font-size: 14px;
	font-weight: 600;
}
.contentXS {
	font-size: 12px;
	font-weight: 600;
}

/* Tablet CSS */
@media screen and (min-width: 800px) {
	body {
		padding: 80px 200px 0px 200px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1144px) {
	body {
		padding: 80px 300px 0px 300px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1340px) {
	body {
		padding: 80px 400px 0px 400px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1440px) {
	body {
		padding: 80px 500px 0px 500px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1640px) {
	body {
		padding: 80px 600px 0px 600px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1840px) {
	body {
		padding: 80px 700px 0px 700px;
	}
}

.alert {
	position: fixed;
	top: calc(100vh - 120px);
	left: 50%;
	transform: translateX(-50%);
	padding: 4px 8px;
	border-radius: 4px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	max-width: 300px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	font-weight: 500;
	z-index: 9999;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
	transition: all 0.2s ease;
}
.alert .icon {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 8px;
}

.alert-success {
	background-color: var(--c-success);
	color: var(--c-primary-dark);
}
.alert-danger {
	background-color: var(--c-danger);
	color: var(--c-light);
}
.alert-warning {
	background-color: var(--c-warning);
	color: var(--c-light);
}

.loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.5);
	z-index: 9999;
}
.loading .card {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	/* width: 300px;
	height: 300px; */
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 16px 32px;
	border-radius: 4px;
	background: var(--c-light);
	/* box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.3); */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.loading .card img {
	width: 50px;
}
.loading .card p {
	font-size: 18px;
	font-weight: 500;
	margin-top: 16px;
}

.bottomNav {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 72px;
	background: var(--c-light);
	box-shadow: 0px -2px 1px rgba(0, 0, 0, 0.05);
	z-index: 99;
	display: flex;
	padding: 0px 20px;
	justify-content: center;
	align-items: center;
}

.bottomNavLink {
	width: 100%;
	height: 100%;
	display: flex;
	color: var(--c-primary-dark);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	transition: all 0.15s ease;
}
.bottomNavLink::before {
	content: "";
	position: absolute;
	top: 0;
	left: auto;
	width: 0%;
	height: 3px;
	border-radius: 50px;
	background: var(--c-primary-dark);
	transition: all 0.15s ease-in-out;
}

.bottomNavLink.active {
	color: var(--c-primary);
}
.bottomNavLink.active::before {
	width: 30%;
	background: var(--c-primary);
}

/* Desktop CSS */
@media screen and (min-width: 800px) {
	.bottomNav {
		padding: 0 200px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1144px) {
	.bottomNav {
		padding: 0 300px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1340px) {
	.bottomNav {
		padding: 0 400px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1440px) {
	.bottomNav {
		padding: 0 500px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1640px) {
	.bottomNav {
		padding: 0 600px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1840px) {
	.bottomNav {
		padding: 0 700px;
	}
}

.header {
	position: fixed;
	top: 0;
	left: 0;
	padding: 0 20px;
	width: 100%;
	height: 72px;
	background-color: var(--c-light);
	color: var(--c-primary-dark);
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 10;
}

.header .icon {
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: column;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
}
/* .header .icon .bar {
	height: 3px;
	margin: 0;
	padding: 0;
	stroke: 3px solid var(--c-primary-dark);
	border-radius: 50px;
	background-color: var(--c-primary-dark);
	transition: all 0.2s ease-in-out;
}
.header .icon .bar1 {
	width: 100%;
}

.header .icon .bar2 {
	width: 50%;
}
.header .icon.open {
	justify-content: center;
}
.header .icon.open .bar1 {
	width: 30px;
	transform: rotate(45deg);
	transform-origin: center;
}
.header .icon.open .bar2 {
	width: 30px;
	transform: rotate(-45deg);
	transform-origin: center;
} */

.header .items {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.header .items .item {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 12px;
	height: 100%;
}

.header .items .item .dococoin {
	position: relative;
	transition: all 0.2s ease-in-out;
	background-color: var(--c-primary-dark);
	padding: 4px 12px 4px 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	color: var(--c-light);
}

.header .items .item .dococoin .dococoinIcon {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	left: -16%;
	top: 50%;
	transform: translateY(-50%);
}
.header .items .item .dococoin .dococoinIcon img {
	width: 38px;
}
.header .items .item .docostore {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

/* Desktop CSS */
@media screen and (min-width: 800px) {
	.header {
		padding: 0 200px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1144px) {
	.header {
		padding: 0 300px;
	}
	.header > .icon {
		visibility: hidden;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1340px) {
	.header {
		padding: 0 400px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1440px) {
	.header {
		padding: 0 500px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1640px) {
	.header {
		padding: 0 600px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1840px) {
	.header {
		padding: 0 700px;
	}
}

.sidebar {
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 0;
	left: 0;
	width: 70%;
	height: calc(100vh - 72px);
	background: var(--c-light);
	padding: 30px 20px;
	padding-bottom: 72px;
	transform: translateX(-100%);
	transition: all 0.2s ease-in-out;
}
.sidebar.open {
	z-index: 999;
	transform: translateX(0);
	box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.3);
}

.sidebar .sidebarHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}
.sidebar .sidebarHeader .closeIcon {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.sidebar .sidebarHeader .sidebarLogo {
	height: 100%;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.sidebar .sidebarHeader .sidebarLogo img {
	width: 80%;
}

.sidebarLinks {
	margin: 12px 0;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	/* flex: 1; */
}
.sidebarControls {
	margin: 12px 0;
}
.sidebarLink.danger {
	margin: 4px 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 16px;
	border-radius: 4px;
	background: var(--c-danger);
	color: var(--c-light);
	transition: all 0.2s ease;
}

.sidebarLink.danger:hover {
	-webkit-filter: contrast(120%);
	        filter: contrast(120%);
	cursor: pointer;
}
.sidebarLink.danger:active {
	-webkit-filter: contrast(120%);
	        filter: contrast(120%);
	transform: scale(0.97);
}
.sidebarLink.danger .title {
	font-size: 18px;
	font-weight: 500;
}
.sidebarLink.danger .icon {
	height: 100%;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}
.sidebarDetails {
	margin: 12px 0;
	width: 100%;
	padding: 12px 16px;
}
.sidebarDetails p {
	margin: 8px 0;
}
.sidebarDetails .icons {
	display: flex;
	align-items: center;
}
.sidebarDetails .icons img {
	margin-right: 16px;
}

/* Desktop CSS */
@media screen and (min-width: 800px) {
	.sidebar {
		width: 30%;
		box-shadow: none;
		/* transform: translate(0); */
	}
}
/* Desktop CSS */
@media screen and (min-width: 1144px) {
	.sidebar {
		width: 20%;
		box-shadow: none;
		transform: translate(0);
		border-right: 1px solid var(--c-lightGrey);
	}
	.sidebar.open {
		box-shadow: none;
	}
	.sidebar .sidebarHeader .closeIcon {
		display: none;
	}
	.sidebar .sidebarHeader .sidebarLogo img {
		width: 50%;
	}
}

.sidebarLink {
	margin: 8px 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 16px;
	border-radius: 4px;
	transition: all 0.2s ease;
}
.sidebarLink .title {
	font-size: 18px;
	font-weight: 500;
}
.sidebarLink .icon {
	height: 100%;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: center;
}
.sidebarLink.active {
	background-color: var(--c-primary);
	color: var(--c-light);
}
.sidebarLink:hover {
	-webkit-filter: contrast(120%);
	        filter: contrast(120%);
	cursor: pointer;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}
.sidebarLink:active {
	-webkit-filter: contrast(120%);
	        filter: contrast(120%);
	transform: scale(0.97);
}

.appScreen {
	margin-bottom: 100px;
}

.profile .profileHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.profile .profileContent {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	border-radius: 4px;
	background-color: var(--c-light);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
}
.profile .profileContent .profileImage {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.profile .profileContent .profileImage img {
	/* width: 164px; */
	height: 164px;
	/* border-radius: 50%; */
	-webkit-clip-path: circle();
	        clip-path: circle();
	object-fit: contain;
}
.profile .profileContent .profileInfo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.profile .profileContent .profileInfo .profileName {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.profile .profileContent .profileInfo .profileBirthday {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	border-radius: 4px;
	background-color: var(--c-primary-light);
}
.profile .profileContent .profileInfo .profileBirthday .emoji {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 8px;
}
.profile .profileContent .profileInfo .profileBirthday .emoji img {
	width: 24px;
}

.profile .profileContent .profileInfo .profileEmail {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.profile .profileContent .profileInfo .profileEmail h3 {
	position: relative;
}
.profile .profileContent .profileInfo .profileEmail .icon {
	position: absolute;
	cursor: pointer;
	right: -32px;
	top: 50%;
	transform: translateY(-50%);
}
.profile .profileContent .profileInfo .profilePhone {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.profile .profileContent .profileInfo .profilePhone h3 {
	position: relative;
}
.profile .profileContent .profileInfo .profilePhone .icon {
	position: absolute;
	cursor: pointer;
	right: -32px;
	top: 50%;
	transform: translateY(-50%);
}
.profile .profileContent .profileInfo .profileSocials {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.profile .profileContent .profileInfo .profileSocials .profileSocialIcon {
	margin: 0 10px;
}
.profile .profileContent .profileInfo .profileSocials .profileSocialIcon img {
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.profile .profileContent .title {
	width: 100%;
	margin-bottom: 4px;
}
.profile .profileContent .profileBio {
	width: 100%;
}
.profile .profileContent .profileInterests {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}
.profile .profileContent .profileInterests span {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	margin-right: 4px;
	margin-bottom: 4px;
	padding: 4px 8px;
	border-radius: 4px;
	background-color: var(--c-primary-dark);
	color: var(--c-light);
	font-size: 12px;
	font-weight: 500;
}

.tile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 46%;
	padding: 16px;
	margin: 4px;
	border-radius: 4px;
	background-color: var(--c-light);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
	transition: all 0.2s ease-in-out;
}
.tile .tileIcon {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 16px 0;
	transition: all 0.2s ease-in-out;
}
.tile:hover .tileIcon {
	transform: translateY(-4px);
}
.tile .tileIcon img {
	width: 72px;
}
.tile .tileTitle {
	font-size: 20px;
	font-weight: 600;
}
.tile:hover {
	-webkit-filter: contrast(120%);
	        filter: contrast(120%);
	cursor: pointer;
}

.tile:active {
	-webkit-filter: contrast(120%);
	        filter: contrast(120%);
	transform: scale(0.97);
}

.explore .exploreTiles {
	display: flex;
	flex-wrap: wrap;
	margin-top: 12px;
}

.userCard {
	padding: 12px 16px;
	border-radius: 4px;
	background-color: var(--c-light);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;
	margin-bottom: 8px;
	align-items: flex-start;
	justify-content: center;
}
.userCard .userInfo {
	display: flex;
	width: 100%;
}
.userCard .userInfo .userImage {
	width: 64px;
	height: 64px;
	margin-right: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.userCard .userInfo .userImage img {
	width: 100%;
	/* border-radius: 50%; */
	-webkit-clip-path: circle();
	        clip-path: circle();
	object-fit: cover;
}

.userCard .userInfo .userName {
	display: flex;
	flex: 1 1;
	align-items: center;
	justify-content: flex-start;
	font-size: 18px;
	font-weight: 600;
	cursor: pointer;
}
.userCard .userInfo .connect {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.userCard .userDetails {
	margin: 8px 0px;
}
.userCard .userDetails .userBio {
	font-size: 18px;
	font-weight: 600;
	color: var(--c-primary-dark);
	overflow: hidden;
	text-overflow: ellipsis;
}

.userCard .userDetails .userInterests {
	display: flex;
	flex-wrap: wrap;
}
.userCard .userDetails .userInterests .userInterest {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	margin-right: 4px;
	margin-bottom: 4px;
	padding: 4px 8px;
	border-radius: 4px;
	background-color: var(--c-primary-dark);
	color: var(--c-light);
	font-size: 12px;
	font-weight: 500;
}
.userCard .userBtns {
	width: 100%;
	display: flex;
}
.userCard .userDetails .userContact {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.userCard .userDetails .userContact img {
	width: 32px;
	margin-right: 8px;
}

.networkHeader {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}
.networkHeader .networkBtn {
	width: 100%;
	margin-bottom: 12px;
	display: flex;
	padding: 12px 16px;
	border-radius: 4px;
	justify-content: space-between;
	align-items: center;
	background-color: var(--c-primary-light);
	color: var(--c-primary);
	font-size: 16px;
	font-weight: 600;
}

.network .tabs {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 16px;
	font-weight: 600;
	margin: 12px 0;
}
.network .tabs .tab {
	margin-right: 16px;
	position: relative;
	cursor: pointer;
	/* display: flex;
	align-items: center;
	justify-content: flex-start; */
	transition: all 0.2s ease;
	-webkit-filter: opacity(70%);
	        filter: opacity(70%);
}
.network .tabs .tab:last-child {
	margin-right: 0;
}
.network .tabs .tab::before {
	content: '';
	position: absolute;
	bottom: -8px;
	left: 0;
	border-radius: 50px;
	height: 3px;
	width: 0px;
	background-color: var(--c-primary-dark);
}

.network .tabs .tab.selected {
	-webkit-filter: opacity(100%);
	        filter: opacity(100%);
	color: var(--c-primary);
}
.network .tabs .tab.selected::before {
	width: 100%;
	background-color: var(--c-primary);
}

.pageHeader {
	position: fixed;
	top: 0;
	left: 0;
	padding: 0 20px;
	width: 100vw;
	height: 72px;
	background-color: var(--c-primary-dark);
	color: var(--c-light);
	display: flex;
	justify-content: flex-start;
	align-items: center;
	z-index: 10;
}

.pageHeader .icon {
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
}

.pageHeader .title {
	width: 100%;
	margin-left: 8px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
/* Desktop CSS */
@media screen and (min-width: 800px) {
	.pageHeader {
		padding: 0 200px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1144px) {
	.pageHeader {
		padding: 0 300px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1340px) {
	.pageHeader {
		padding: 0 400px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1440px) {
	.pageHeader {
		padding: 0 500px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1640px) {
	.pageHeader {
		padding: 0 600px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1840px) {
	.pageHeader {
		padding: 0 700px;
	}
}

.pageScreen {
	width: 100%;
	min-height: 100vh;
	position: relative;
}
.pageScreen .goToTop {
	position: fixed;
}

.goToTop {
	position: fixed;
	bottom: 20px;
	right: 20px;
	width: 58px;
	height: 58px;
	-webkit-clip-path: circle();
	        clip-path: circle();
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--c-primary);
	color: var(--c-light);
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.goToTop svg {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.dococoins {
	width: 100%;
	display: flex;
	flex-direction: column;
}
.dococoins .dococoinsHeader {
	padding-top: 32px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.dococoins .dococoinsHeader .dococoinsIcon {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.dococoins .dococoinsHeader .dococoinsIcon img {
	width: 180px;
	animation: dococoinsIcon 1.5s infinite ease-in-out;
}

@keyframes dococoinsIcon {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-10px);
	}
	100% {
		transform: translateY(0);
	}
}
.dococoins .dococoinsHeader .dococoinsCount {
	margin: 8px 0;
	background-color: var(--c-primary-dark);
	min-width: 50%;
	max-width: -webkit-fit-content;
	max-width: -moz-fit-content;
	max-width: fit-content;
	padding: 4px 16px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 32px;
	font-weight: 700;
	color: var(--c-yellow);
}
.dococoins .dococoinsHeader .dococoinsText {
	width: 50%;
	margin: 8px 0;
	font-size: 22px;
	font-weight: 700;
	color: var(--c-primary-dark);
}

.transactionCard {
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: var(--c-light);
	border-radius: 4px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
	padding: 12px 16px;
	margin: 8px 0;
	cursor: pointer;
}

.transactionCard .transactionInfo {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.transactionCard .transactionInfo .transactionIcon {
	padding: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: var(--c-primary-dark);
	margin-right: 12px;
}
.transactionCard .transactionInfo .transactionDetails {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
.transactionCard .transactionInfo .transactionDetails .transactionTitle {
	font-size: 18px;
	font-weight: 500;
	color: var(--c-primary-dark);
}
.transactionCard .transactionInfo .transactionDetails .transactionAmount {
	font-size: 20px;
	font-weight: 700;
	color: var(--c-primary-dark);
}
.transactionCard .transactionMoreDetails {
	padding: 16px;
	font-size: 16px;
	font-weight: 500;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
.transactionCard .transactionMoreDetails .transactionDate,
.transactionCard .transactionMoreDetails .transactionTime,
.transactionCard .transactionMoreDetails .transactionId {
	margin: 4px 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.btn {
	padding-top: 12px;
	padding-bottom: 12px;
	margin: 4px 0;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	transition: all 0.2s ease;
	font-size: 18px;
	font-weight: 500;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}
.btn .icon {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 8px;
}
.btn .icon img {
	width: 20px;
}
.btn:hover {
	-webkit-filter: contrast(120%);
	        filter: contrast(120%);
	cursor: pointer;
}

.btn:active {
	-webkit-filter: contrast(120%);
	        filter: contrast(120%);
	transform: scale(0.97);
}
.btn-primary {
	background-color: var(--c-primary);
	border: 2px solid var(--c-primary);
	color: var(--c-light);
}
.btn-primary-light {
	background-color: var(--c-primary-light);
	border: 2px solid var(--c-primary-light);
	color: var(--c-primary);
}
.btn-secondary {
	background-color: var(--c-light);
	border: 2px solid var(--c-primary);
	font-weight: 600;
	color: var(--c-primary);
}
.btn-google {
	background-color: var(--c-light);
	border: 2px solid var(--c-light);
	color: var(--c-primary-dark);
}

.resourceCategories .categoryList {
	display: flex;
	flex-direction: column;
	/* flex-wrap: wrap; */
	margin-top: 8px;
}
.resourceCategories .categoryList .category {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 4px;
	background-color: var(--c-primary-light);
	padding: 16px;
	margin: 4px 0;
	width: 100%;
	font-size: 18px;
	font-weight: 600;
}

.events .tabs {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 20px;
	font-weight: 600;
	margin: 16px 0;
}

.events .recordedEvents {
	margin: 12px 0;
	display: flex;
	padding: 12px 16px;
	border-radius: 4px;
	justify-content: space-between;
	align-items: center;
	background-color: var(--c-primary-light);
	color: var(--c-primary);
	font-size: 18px;
	font-weight: 500;
}

.events .tabs .tab {
	margin-right: 16px;
	position: relative;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	transition: all 0.2s ease;
	-webkit-filter: opacity(70%);
	        filter: opacity(70%);
}
.events .tabs .tab::before {
	content: '';
	position: absolute;
	bottom: -8px;
	left: 0;
	border-radius: 50px;
	height: 3px;
	width: 0px;
	background-color: var(--c-primary-dark);
}

.events .tabs .tab.selected {
	-webkit-filter: opacity(100%);
	        filter: opacity(100%);
	color: var(--c-primary);
}
.events .tabs .tab.selected::before {
	width: 100%;
	background-color: var(--c-primary);
}
.events .eventsList {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.events .eventsList .noEvents {
	text-transform: lowercase;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	margin-top: 16px;
}

.eventCard {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-transform: lowercase;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: var(--c-light);
	border-radius: 4px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
	padding: 8px;
	margin: 8px 0;
}

.eventCard .eventCardImage {
	width: 100%;
}

.eventCard .eventCardImage img {
	width: 100%;
	border-radius: 4px;
}
.eventCard .eventCardTags {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	/* position: absolute;
	top: 8px;
	left: 8px; */
	margin: 4px 0;
	width: 100%;
}
.eventCard .eventCardTags .eventCardTag {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
	border-radius: 4px;
	margin-right: 4px;
	font-size: 12px;
	font-weight: 500;
	color: var(--c-light);
	background-color: var(--c-primary);
}
.eventCard .eventCardContent {
	width: 100%;
}
.eventCard .eventCardContentInfo {
	width: 100%;
	margin: 4px 0;
	font-size: 18px;
	font-weight: 600;
}
.eventCard .eventCardButtons {
	margin-top: 16px;
}

.input {
	width: 100%;
	padding: 4px;
	outline: none;
	border: none;
	border-bottom: 2px solid var(--c-disabled);
	background-color: transparent;
	color: var(--c-primary-dark);
	transition: all 0.2s ease-in-out;
}
.input:focus,
.input:active {
	outline: none;
	border-bottom: 2px solid var(--c-primary);
	color: var(--c-primary);
}
.input.disabled {
	border-bottom: 2px solid var(--c-disabled);
	color: var(--c-grey);
}

.authScreen {
	width: 100%;
	min-height: 95vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding-bottom: 30px;
}

.authScreen .form {
	width: 100%;
}

.authScreen .inputGroup {
	width: 100%;
	display: flex;
	margin: 30px 0;
	position: relative;
}

.authScreen .inputGroup > .icon {
	position: absolute;
	background-color: var(--c-light);
	display: flex;
	justify-content: center;
	align-items: center;
	height: 90%;
	right: 0;
	color: var(--c-disabled);
	z-index: 1;
	transition: all 0.2s ease-in-out;
}

.authScreen .inputGroup.seperator {
	display: flex;
	justify-content: center;
	align-items: center;
}

.authScreen .inputGroup.seperator p {
	padding: 0 16px;
	color: var(--c-disabled);
	font-size: 18px;
	font-weight: 500;
}

.authScreen .inputGroup.seperator .bar {
	height: 2px;
	background-color: var(--c-disabled);
	width: 100%;
}

.authScreen .text {
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--c-grey);
}

.pastEventsCard {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--c-light);
	border-radius: 4px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
	padding: 8px;
	margin: 8px 0;
}
.pastEventsCard .pastEventsCardEmbed {
	width: 100%;
}
.pastEventsCard .pastEventsCardImage {
	width: 100%;
}
.pastEventsCard .pastEventsCardImage img {
	width: 100%;
	border-radius: 4px;
}
.pastEventsCard .pastEventsCardTags {
	display: flex;
	width: 100%;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 2px;
}
.pastEventsCard .pastEventsCardTags .pastEventsCardTag {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--c-primary-dark);
	color: var(--c-light);
	border-radius: 4px;
	padding: 4px 12px;
	margin-right: 4px;
}
.pastEventsCard .pastEventsCardTags .pastEventsCardTag:last-child {
	margin-right: 0;
}
.pastEventsCard .pastEventsCardContent {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-bottom: 8px;
}
.pastEventsCard .pastEventsCardContent .pastEventsCardTitle {
	margin-bottom: 0px;
	font-size: 20px;
	font-weight: 600;
	color: var(--c-primary);
}

.pastEvents {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.pastEvents .pastEventsList {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 64px;
}
.pastEvents .pastEventsFilters,
.pastEvents .pastEventsFilters .filter {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.pastEvents .pastEventsFilters .filter .filterButton {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 24px;
	border-radius: 4px;
	background-color: var(--c-primary-light);
	color: var(--c-primary);
	font-size: 18px;
	font-weight: 600;
	cursor: pointer;
}
.pastEvents .pastEventsFilters .filter .filterOptions {
	position: absolute;
	top: 100%;
	display: flex;
	flex-direction: column;
	padding: 8px;
	width: 100%;
	margin-top: 8px;
	border-radius: 4px;
	background-color: var(--c-light);
	color: var(--c-primary);
	font-size: 18px;
	font-weight: 600;
	cursor: pointer;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	z-index: 99;
}
.pastEvents .pastEventsFilters .filter .filterOptions .filterOption {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 16px;
	border-radius: 4px;
	margin-bottom: 8px;
	transition: all 0.2s ease-in-out;
}
.pastEvents .pastEventsFilters .filter .filterOptions .filterOption:hover {
	background-color: var(--c-primary);
	color: var(--c-light);
}

.clubs {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.clubs .clubsList {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	margin-top: 16px;
}
.clubs .clubsList .club {
	display: flex;
	cursor: pointer;
	width: 100%;
	justify-content: flex-start;
	align-items: center;
	padding: 8px 16px;
	background-color: var(--c-light);
	border-radius: 4px;
	margin-bottom: 8px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}
.clubs .clubsList .club .clubIcon {
	width: 64px;
	height: 100%;
	margin-right: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.clubs .clubsList .club .clubIcon img {
	width: 100%;
}
.clubs .clubsList .club .clubInfo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
}

.clubPage .clubHeader {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}
.clubPage .clubHeader .clubInfo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-left: 16px;
}
.clubPage .clubHeader .clubIcon {
	display: flex;
	justify-content: center;
	align-items: center;
}
.clubPage .clubHeader .clubIcon img {
	width: 64px;
	height: 64px;
}

.resourceCard {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 8px;
	margin: 8px 0px;
	border-radius: 4px;
	background-color: var(--c-light);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}

.resourceCard .resourceCardHeader {
	display: flex;
	/* align-items: center; */
	width: 100%;
	height: 100%;
}

.resourceCard .resourceCardHeader .resourceCardIcon {
	width: 64px;
	display: block;
	border-radius: 4px;
	background-color: var(--c-primary-dark);
	margin-right: 12px;
}
.resourceCard .resourceCardHeader .resourceCardIcon .img {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.resourceCard .resourceCardHeader .resourceCardIcon .img img {
	width: 28px;
}
.resourceCard .resourceCardHeader .resourceCardContent {
	display: flex;
	flex: 1 1;
	flex-direction: column;
	justify-content: flex-start;
	margin-right: 8px;
	cursor: pointer;
}
.resourceCard .resourceCardHeader .resourceCardContent .resourceCardTitle {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex: 1 1;
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 8px;
}
.resourceCard .resourceCardHeader .resourceCardContent .resourceCardLevel {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 2px;
}
.resourceCard .resourceCardHeader .resourceCardContent .resourceCardLevel span {
	color: var(--c-light);
	background-color: var(--c-primary-dark);
	padding: 4px 8px;
	border-radius: 4px;
	margin-right: 4px;
}
.resourceCard .resourceCardHeader .resourceCardSaveHolder {
	display: block;
	width: 42px;
	/* border: 2px solid var(--c-primary-dark); */
	/* background-color: var(--c-light); */
	/* background-color: var(--c-primary-dark); */
	border-radius: 4px;
}
.resourceCard .resourceCardHeader .resourceCardSave {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
	/* color: var(--c-primary-dark); */
	/* color: var(--c-light); */
}
.resourceCard .resourceCardHeader .resourceCardSave .resourceCardSaveIcon.rotate {
	animation: rotate 3s infinite;
}
@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.resourceCard .resourceCardHeader .resourceCardSave .resourceCardSaveIcon {
	padding: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.resourceCard .resourceCardHeader .resourceCardSave .resourceCardSaveCount {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	font-weight: 600;
	padding: 4px;
}

.resourceCard .resourceCardInfo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	padding: 8px;
	margin-top: 8px;
}
.resourceCard .resourceCardInfo .resourceCardInfoDetails {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	margin-bottom: 8px;
	padding: 0;
}
.resourceCard .resourceCardInfo .resourceCardInfoDetails div {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	margin: 8px 0;
}
.resourceCard .resourceCardInfo .resourceCardInfoDetails div span {
	font-weight: 600;
}
.resourceCard .resourceCardInfo .resourceCardInfoDetails div .givenBy {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}
.resourceCard .resourceCardInfo .resourceCardInfoDetails div .givenBy img {
	width: 24px;
	height: 24px;
	border-radius: 4px;
	margin-right: 4px;
}
.resourceCard .resourceCardInfo .resourceCardInfoLink {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.myResources .search {
	margin: 16px 0;
	background-color: var(--c-light);
	border-radius: 4px;
	box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2);
	padding: 16px;
}

.editProfile .basicInfo .inputGroup {
	display: flex;
	align-items: flex-end;
	margin: 16px 0;
}
.editProfile .basicInfo .inputGroup .prefix {
	all: unset;
	position: relative;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	height: 100%;
	padding: 4px;
	outline: none;
	border: none;
	border-bottom: 2px solid var(--c-disabled);
	background-color: transparent;
	color: var(--c-grey);
	transition: all 0.2s ease-in-out;
}
.editProfile .basicInfo .inputGroup .countryCode {
	all: unset;
	position: relative;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	height: 100%;
	padding: 4px;
	outline: none;
	border: none;
	border-bottom: 2px solid var(--c-disabled);
	background-color: transparent;
	color: var(--c-primary-dark);
	transition: all 0.2s ease-in-out;
}
.editProfile .basicInfo .inputGroup .countryCode:focus,
.editProfile .basicInfo .inputGroup .countryCode:active {
	outline: none;
	border-bottom: 2px solid var(--c-primary);
	color: var(--c-primary);
}

.editProfile .basicInfo .inputGroup .countryCode .countryCodeButton {
	display: flex;
	align-items: center;
}
.editProfile .basicInfo .inputGroup .countryCode .countryCodeButton .img {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 6px;
}
.editProfile .basicInfo .inputGroup .countryCode .countryCodeButton .img img {
	width: 24px;
}

.editProfile .basicInfo .inputGroup .countryCodeOptions {
	position: absolute;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	height: 200px;
	transition: all 0.2s ease-in-out;
	background-color: var(--c-light);
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	list-style-type: none;
	top: 45px;
	left: 0;
	padding: 4px;
	border-radius: 4px;
	overflow-x: hidden;
	overflow-y: scroll;
	z-index: 1;
}

/* width */
.editProfile .basicInfo .inputGroup .countryCodeOptions::-webkit-scrollbar {
	width: 4px;
	border-radius: 4px;
}

/* Track */
.editProfile .basicInfo .inputGroup .countryCodeOptions::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 4px;
}

/* Handle */
.editProfile .basicInfo .inputGroup .countryCodeOptions::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 4px;
}

/* Handle on hover */
.editProfile .basicInfo .inputGroup .countryCodeOptions::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.editProfile .basicInfo .inputGroup .countryCodeOption {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 4px 8px;
	cursor: pointer;
	border-radius: 2px;
	margin: 2px 0;
}
.editProfile .basicInfo .inputGroup .countryCodeOption .img {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 6px;
}
.editProfile .basicInfo .inputGroup .countryCodeOption .img img {
	width: 24px;
}

.editProfile .basicInfo .inputGroup .countryCodeOption:hover {
	background-color: var(--c-primary);
	color: var(--c-light);
}
.editProfile .basicInfo .inputGroup .emoji {
	width: 52px;
	height: 100%;
	display: flex;
	align-items: center;
}
.editProfile .basicInfo .inputGroup .emoji img {
	width: 32px;
}
.editProfile .basicInfo .inputGroup .wordCounter {
	display: flex;
	height: 100%;
	align-items: center;
}
.editProfile .basicInfo .inputGroup > textarea {
	width: 100%;
	height: 125px !important;
	outline: none;
}
.editProfile .basicInfo .interests {
	display: flex;
	flex-wrap: wrap;
}
.editProfile .basicInfo .interests p {
	margin-bottom: 4px;
}
.editProfile .basicInfo .interests .interestPills {
	display: flex;
	flex-wrap: wrap;
}
.editProfile .basicInfo .interests .interestPills .interest {
	display: flex;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	align-items: center;
	margin-right: 4px;
	margin-bottom: 4px;
	background-color: var(--c-light);
	color: var(--c-disabled);
	border-radius: 4px;
	padding: 2px 8px;
	cursor: pointer;
	border: 2px solid var(--c-primary-light);
	transition: all 0.2s ease-in-out;
}
.editProfile .basicInfo .interests .interestPills .interest.selected {
	background-color: var(--c-primary);
	color: var(--c-light);
	border: 2px solid var(--c-primary);
}
.editProfile .basicInfo .imageUpload {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}
.editProfile .basicInfo .imageUpload .image {
	position: relative;
	width: 128px;
	height: 128px;
	margin: 24px 0 16px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-clip-path: circle(50%);
	        clip-path: circle(50%);
}
.editProfile .basicInfo .imageUpload .image img {
	width: 128px;
	object-fit: cover;
}
.editProfile .basicInfo .imageUpload .changeImage {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.editProfile .basicInfo .imageUpload .changeImage .imageUploadBtn {
	visibility: hidden;
}
.editProfile .basicInfo .imageUpload .changeImage .imageUploadBtn::-webkit-file-upload-button {
	visibility: hidden;
	width: 0;
}
.editProfile .basicInfo .imageUpload .changeImage .imageUploadBtn::before {
	content: 'Upload Image';
	visibility: visible;
	display: inline-block;
	background-color: var(--c-primary-light);
	color: var(--c-primary);
	padding: 8px 16px;
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}
.editProfile .basicInfo .select {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	background-color: var(--c-primary-light);
	color: var(--c-primary);
	border-radius: 4px;
	padding: 4px;
}
.editProfile .basicInfo .select .option {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px;
	cursor: pointer;
	margin-right: 4px;
	border-radius: 4px;
	transition: all 0.2s ease-in-out;
}
.editProfile .basicInfo .select .option:last-child {
	margin-right: 0;
}
.editProfile .basicInfo .select .option.selected {
	background-color: var(--c-primary);
	color: var(--c-light);
}
.editProfile .basicInfo .inputGroup .dob {
	all: unset;
	position: relative;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	height: 100%;
	padding: 4px 8px;
	outline: none;
	border: none;
	border-bottom: 2px solid var(--c-disabled);
	background-color: transparent;
	color: var(--c-primary-dark);
	transition: all 0.2s ease-in-out;
}
.editProfile .basicInfo .inputGroup .dob:focus,
.editProfile .basicInfo .inputGroup .dob:active {
	outline: none;
	border-bottom: 2px solid var(--c-primary);
	color: var(--c-primary);
}

.editProfile .basicInfo .inputGroup .dob .dobButton {
	display: flex;
	align-items: center;
}
.editProfile .basicInfo .inputGroup .dob .dobButton .img {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 6px;
}
.editProfile .basicInfo .inputGroup .dob .dobButton .img img {
	width: 24px;
}

.editProfile .basicInfo .inputGroup .dobOptions {
	position: absolute;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	height: 200px;
	transition: all 0.2s ease-in-out;
	background-color: var(--c-light);
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	list-style-type: none;
	top: 45px;
	left: 0;
	padding: 4px;
	border-radius: 4px;
	overflow-x: hidden;
	overflow-y: scroll;
	z-index: 1;
}

/* width */
.editProfile .basicInfo .inputGroup .dobOptions::-webkit-scrollbar {
	width: 4px;
	border-radius: 4px;
}

/* Track */
.editProfile .basicInfo .inputGroup .dobOptions::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 4px;
}

/* Handle */
.editProfile .basicInfo .inputGroup .dobOptions::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 4px;
}

/* Handle on hover */
.editProfile .basicInfo .inputGroup .dobOptions::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.editProfile .basicInfo .inputGroup .dobOption {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 4px 8px;
	cursor: pointer;
	border-radius: 2px;
	margin: 2px 0;
}
.editProfile .basicInfo .inputGroup .dobOption .img {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 6px;
}
.editProfile .basicInfo .inputGroup .dobOption .img img {
	width: 24px;
}

.editProfile .basicInfo .inputGroup .dobOption:hover {
	background-color: var(--c-primary);
	color: var(--c-light);
}

.viewResources {
	margin-bottom: 52px;
}
.viewResources .search {
	margin: 16px 0;
	background-color: var(--c-light);
	border-radius: 4px;
	box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2);
	padding: 16px;
}

.requests .tabs {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 20px;
	font-weight: 600;
	margin: 12px 0;
}
.requests .tabs .tab {
	margin-right: 16px;
	position: relative;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	transition: all 0.2s ease;
	-webkit-filter: opacity(70%);
	        filter: opacity(70%);
}
.requests .tabs .tab::before {
	content: '';
	position: absolute;
	bottom: -8px;
	left: 0;
	border-radius: 50px;
	height: 3px;
	width: 0px;
	background-color: var(--c-primary-dark);
}

.requests .tabs .tab.selected {
	-webkit-filter: opacity(100%);
	        filter: opacity(100%);
	color: var(--c-primary);
}
.requests .tabs .tab.selected::before {
	width: 100%;
	background-color: var(--c-primary);
}
.requests .requestsList {
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	width: 100%;
}
.requests .requestsList .request {
	padding: 12px 16px;
	border-radius: 4px;
	background-color: var(--c-light);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;
	margin-bottom: 8px;
	align-items: flex-start;
	justify-content: center;
	transition: all 0.2s ease;
}
.requests .requestsList .request .requestHeader {
	display: flex;
	width: 100%;
}
.requests .requestsList .request .requestHeader .requestImage {
	width: 64px;
	height: 64px;
	margin-right: 16px;
	display: flex;
	justify-content: center;
	cursor: pointer;
	align-items: center;
}
.requests .requestsList .request .requestHeader .requestImage img {
	width: 100%;
	/* border-radius: 50%; */
	-webkit-clip-path: circle();
	        clip-path: circle();
	object-fit: cover;
}
.requests .requestsList .request .requestHeader .requestName {
	display: flex;
	cursor: pointer;
	flex: 1 1;
	align-items: center;
	justify-content: flex-start;
	font-size: 18px;
	font-weight: 600;
}
.requests .requestsList .request .requestHeader .requestIcons {
	display: flex;
	justify-content: center;
	align-items: center;
}
.requests .requestsList .request .requestHeader .requestIcons .requestIcon {
	margin-right: 8px;
	padding: 6px;
	border-radius: 50%;
	background-color: var(--c-primary-dark);
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}
.requests .requestsList .request .requestHeader .requestIcons .requestIcon:last-child {
	margin-right: 0;
}
.requests .requestsList .request .requestBody {
	margin: 8px 0px;
}
.requests .requestsList .request .requestBody .requestBio {
	font-size: 18px;
	font-weight: 600;
	color: var(--c-primary-dark);
	overflow: hidden;
	text-overflow: ellipsis;
}

.requests .requestsList .request .requestBody .requestInterests {
	display: flex;
	flex-wrap: wrap;
}
.requests .requestsList .request .requestBody .requestInterests .requestInterest {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	margin-right: 4px;
	margin-bottom: 4px;
	padding: 4px 8px;
	border-radius: 4px;
	background-color: var(--c-primary-dark);
	color: var(--c-light);
	font-size: 12px;
	font-weight: 500;
}

.contact .contactCard {
	background-color: var(--c-primary-light);
	padding: 20px;
	margin-bottom: 16px;
	border-radius: 4px;
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
}

