.networkHeader {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}
.networkHeader .networkBtn {
	width: 100%;
	margin-bottom: 12px;
	display: flex;
	padding: 12px 16px;
	border-radius: 4px;
	justify-content: space-between;
	align-items: center;
	background-color: var(--c-primary-light);
	color: var(--c-primary);
	font-size: 16px;
	font-weight: 600;
}
