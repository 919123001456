.sidebar {
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 0;
	left: 0;
	width: 70%;
	height: calc(100vh - 72px);
	background: var(--c-light);
	padding: 30px 20px;
	padding-bottom: 72px;
	transform: translateX(-100%);
	transition: all 0.2s ease-in-out;
}
.sidebar.open {
	z-index: 999;
	transform: translateX(0);
	box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.3);
}

.sidebar .sidebarHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}
.sidebar .sidebarHeader .closeIcon {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.sidebar .sidebarHeader .sidebarLogo {
	height: 100%;
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.sidebar .sidebarHeader .sidebarLogo img {
	width: 80%;
}

.sidebarLinks {
	margin: 12px 0;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	/* flex: 1; */
}
.sidebarControls {
	margin: 12px 0;
}
.sidebarLink.danger {
	margin: 4px 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 16px;
	border-radius: 4px;
	background: var(--c-danger);
	color: var(--c-light);
	transition: all 0.2s ease;
}

.sidebarLink.danger:hover {
	filter: contrast(120%);
	cursor: pointer;
}
.sidebarLink.danger:active {
	filter: contrast(120%);
	transform: scale(0.97);
}
.sidebarLink.danger .title {
	font-size: 18px;
	font-weight: 500;
}
.sidebarLink.danger .icon {
	height: 100%;
	width: fit-content;
}
.sidebarDetails {
	margin: 12px 0;
	width: 100%;
	padding: 12px 16px;
}
.sidebarDetails p {
	margin: 8px 0;
}
.sidebarDetails .icons {
	display: flex;
	align-items: center;
}
.sidebarDetails .icons img {
	margin-right: 16px;
}

/* Desktop CSS */
@media screen and (min-width: 800px) {
	.sidebar {
		width: 30%;
		box-shadow: none;
		/* transform: translate(0); */
	}
}
/* Desktop CSS */
@media screen and (min-width: 1144px) {
	.sidebar {
		width: 20%;
		box-shadow: none;
		transform: translate(0);
		border-right: 1px solid var(--c-lightGrey);
	}
	.sidebar.open {
		box-shadow: none;
	}
	.sidebar .sidebarHeader .closeIcon {
		display: none;
	}
	.sidebar .sidebarHeader .sidebarLogo img {
		width: 50%;
	}
}
