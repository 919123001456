.eventCard {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-transform: lowercase;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: var(--c-light);
	border-radius: 4px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
	padding: 8px;
	margin: 8px 0;
}

.eventCard .eventCardImage {
	width: 100%;
}

.eventCard .eventCardImage img {
	width: 100%;
	border-radius: 4px;
}
.eventCard .eventCardTags {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	/* position: absolute;
	top: 8px;
	left: 8px; */
	margin: 4px 0;
	width: 100%;
}
.eventCard .eventCardTags .eventCardTag {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
	border-radius: 4px;
	margin-right: 4px;
	font-size: 12px;
	font-weight: 500;
	color: var(--c-light);
	background-color: var(--c-primary);
}
.eventCard .eventCardContent {
	width: 100%;
}
.eventCard .eventCardContentInfo {
	width: 100%;
	margin: 4px 0;
	font-size: 18px;
	font-weight: 600;
}
.eventCard .eventCardButtons {
	margin-top: 16px;
}
