.bottomNav {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 72px;
	background: var(--c-light);
	box-shadow: 0px -2px 1px rgba(0, 0, 0, 0.05);
	z-index: 99;
	display: flex;
	padding: 0px 20px;
	justify-content: center;
	align-items: center;
}

.bottomNavLink {
	width: 100%;
	height: 100%;
	display: flex;
	color: var(--c-primary-dark);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	transition: all 0.15s ease;
}
.bottomNavLink::before {
	content: "";
	position: absolute;
	top: 0;
	left: auto;
	width: 0%;
	height: 3px;
	border-radius: 50px;
	background: var(--c-primary-dark);
	transition: all 0.15s ease-in-out;
}

.bottomNavLink.active {
	color: var(--c-primary);
}
.bottomNavLink.active::before {
	width: 30%;
	background: var(--c-primary);
}

/* Desktop CSS */
@media screen and (min-width: 800px) {
	.bottomNav {
		padding: 0 200px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1144px) {
	.bottomNav {
		padding: 0 300px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1340px) {
	.bottomNav {
		padding: 0 400px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1440px) {
	.bottomNav {
		padding: 0 500px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1640px) {
	.bottomNav {
		padding: 0 600px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1840px) {
	.bottomNav {
		padding: 0 700px;
	}
}
