.editProfile .basicInfo .inputGroup {
	display: flex;
	align-items: flex-end;
	margin: 16px 0;
}
.editProfile .basicInfo .inputGroup .prefix {
	all: unset;
	position: relative;
	width: fit-content;
	height: 100%;
	padding: 4px;
	outline: none;
	border: none;
	border-bottom: 2px solid var(--c-disabled);
	background-color: transparent;
	color: var(--c-grey);
	transition: all 0.2s ease-in-out;
}
.editProfile .basicInfo .inputGroup .countryCode {
	all: unset;
	position: relative;
	width: fit-content;
	height: 100%;
	padding: 4px;
	outline: none;
	border: none;
	border-bottom: 2px solid var(--c-disabled);
	background-color: transparent;
	color: var(--c-primary-dark);
	transition: all 0.2s ease-in-out;
}
.editProfile .basicInfo .inputGroup .countryCode:focus,
.editProfile .basicInfo .inputGroup .countryCode:active {
	outline: none;
	border-bottom: 2px solid var(--c-primary);
	color: var(--c-primary);
}

.editProfile .basicInfo .inputGroup .countryCode .countryCodeButton {
	display: flex;
	align-items: center;
}
.editProfile .basicInfo .inputGroup .countryCode .countryCodeButton .img {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 6px;
}
.editProfile .basicInfo .inputGroup .countryCode .countryCodeButton .img img {
	width: 24px;
}

.editProfile .basicInfo .inputGroup .countryCodeOptions {
	position: absolute;
	width: fit-content;
	height: 200px;
	transition: all 0.2s ease-in-out;
	background-color: var(--c-light);
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	list-style-type: none;
	top: 45px;
	left: 0;
	padding: 4px;
	border-radius: 4px;
	overflow-x: hidden;
	overflow-y: scroll;
	z-index: 1;
}

/* width */
.editProfile .basicInfo .inputGroup .countryCodeOptions::-webkit-scrollbar {
	width: 4px;
	border-radius: 4px;
}

/* Track */
.editProfile .basicInfo .inputGroup .countryCodeOptions::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 4px;
}

/* Handle */
.editProfile .basicInfo .inputGroup .countryCodeOptions::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 4px;
}

/* Handle on hover */
.editProfile .basicInfo .inputGroup .countryCodeOptions::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.editProfile .basicInfo .inputGroup .countryCodeOption {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 4px 8px;
	cursor: pointer;
	border-radius: 2px;
	margin: 2px 0;
}
.editProfile .basicInfo .inputGroup .countryCodeOption .img {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 6px;
}
.editProfile .basicInfo .inputGroup .countryCodeOption .img img {
	width: 24px;
}

.editProfile .basicInfo .inputGroup .countryCodeOption:hover {
	background-color: var(--c-primary);
	color: var(--c-light);
}
.editProfile .basicInfo .inputGroup .emoji {
	width: 52px;
	height: 100%;
	display: flex;
	align-items: center;
}
.editProfile .basicInfo .inputGroup .emoji img {
	width: 32px;
}
.editProfile .basicInfo .inputGroup .wordCounter {
	display: flex;
	height: 100%;
	align-items: center;
}
.editProfile .basicInfo .inputGroup > textarea {
	width: 100%;
	height: 125px !important;
	outline: none;
}
.editProfile .basicInfo .interests {
	display: flex;
	flex-wrap: wrap;
}
.editProfile .basicInfo .interests p {
	margin-bottom: 4px;
}
.editProfile .basicInfo .interests .interestPills {
	display: flex;
	flex-wrap: wrap;
}
.editProfile .basicInfo .interests .interestPills .interest {
	display: flex;
	width: fit-content;
	align-items: center;
	margin-right: 4px;
	margin-bottom: 4px;
	background-color: var(--c-light);
	color: var(--c-disabled);
	border-radius: 4px;
	padding: 2px 8px;
	cursor: pointer;
	border: 2px solid var(--c-primary-light);
	transition: all 0.2s ease-in-out;
}
.editProfile .basicInfo .interests .interestPills .interest.selected {
	background-color: var(--c-primary);
	color: var(--c-light);
	border: 2px solid var(--c-primary);
}
.editProfile .basicInfo .imageUpload {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}
.editProfile .basicInfo .imageUpload .image {
	position: relative;
	width: 128px;
	height: 128px;
	margin: 24px 0 16px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	clip-path: circle(50%);
}
.editProfile .basicInfo .imageUpload .image img {
	width: 128px;
	object-fit: cover;
}
.editProfile .basicInfo .imageUpload .changeImage {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.editProfile .basicInfo .imageUpload .changeImage .imageUploadBtn {
	visibility: hidden;
}
.editProfile .basicInfo .imageUpload .changeImage .imageUploadBtn::-webkit-file-upload-button {
	visibility: hidden;
	width: 0;
}
.editProfile .basicInfo .imageUpload .changeImage .imageUploadBtn::before {
	content: 'Upload Image';
	visibility: visible;
	display: inline-block;
	background-color: var(--c-primary-light);
	color: var(--c-primary);
	padding: 8px 16px;
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}
.editProfile .basicInfo .select {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	background-color: var(--c-primary-light);
	color: var(--c-primary);
	border-radius: 4px;
	padding: 4px;
}
.editProfile .basicInfo .select .option {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px;
	cursor: pointer;
	margin-right: 4px;
	border-radius: 4px;
	transition: all 0.2s ease-in-out;
}
.editProfile .basicInfo .select .option:last-child {
	margin-right: 0;
}
.editProfile .basicInfo .select .option.selected {
	background-color: var(--c-primary);
	color: var(--c-light);
}
.editProfile .basicInfo .inputGroup .dob {
	all: unset;
	position: relative;
	width: fit-content;
	height: 100%;
	padding: 4px 8px;
	outline: none;
	border: none;
	border-bottom: 2px solid var(--c-disabled);
	background-color: transparent;
	color: var(--c-primary-dark);
	transition: all 0.2s ease-in-out;
}
.editProfile .basicInfo .inputGroup .dob:focus,
.editProfile .basicInfo .inputGroup .dob:active {
	outline: none;
	border-bottom: 2px solid var(--c-primary);
	color: var(--c-primary);
}

.editProfile .basicInfo .inputGroup .dob .dobButton {
	display: flex;
	align-items: center;
}
.editProfile .basicInfo .inputGroup .dob .dobButton .img {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 6px;
}
.editProfile .basicInfo .inputGroup .dob .dobButton .img img {
	width: 24px;
}

.editProfile .basicInfo .inputGroup .dobOptions {
	position: absolute;
	width: fit-content;
	height: 200px;
	transition: all 0.2s ease-in-out;
	background-color: var(--c-light);
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	list-style-type: none;
	top: 45px;
	left: 0;
	padding: 4px;
	border-radius: 4px;
	overflow-x: hidden;
	overflow-y: scroll;
	z-index: 1;
}

/* width */
.editProfile .basicInfo .inputGroup .dobOptions::-webkit-scrollbar {
	width: 4px;
	border-radius: 4px;
}

/* Track */
.editProfile .basicInfo .inputGroup .dobOptions::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 4px;
}

/* Handle */
.editProfile .basicInfo .inputGroup .dobOptions::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 4px;
}

/* Handle on hover */
.editProfile .basicInfo .inputGroup .dobOptions::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.editProfile .basicInfo .inputGroup .dobOption {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 4px 8px;
	cursor: pointer;
	border-radius: 2px;
	margin: 2px 0;
}
.editProfile .basicInfo .inputGroup .dobOption .img {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 6px;
}
.editProfile .basicInfo .inputGroup .dobOption .img img {
	width: 24px;
}

.editProfile .basicInfo .inputGroup .dobOption:hover {
	background-color: var(--c-primary);
	color: var(--c-light);
}
