.clubs {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.clubs .clubsList {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	margin-top: 16px;
}
.clubs .clubsList .club {
	display: flex;
	cursor: pointer;
	width: 100%;
	justify-content: flex-start;
	align-items: center;
	padding: 8px 16px;
	background-color: var(--c-light);
	border-radius: 4px;
	margin-bottom: 8px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}
.clubs .clubsList .club .clubIcon {
	width: 64px;
	height: 100%;
	margin-right: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.clubs .clubsList .club .clubIcon img {
	width: 100%;
}
.clubs .clubsList .club .clubInfo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
}
