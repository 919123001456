.alert {
	position: fixed;
	top: calc(100vh - 120px);
	left: 50%;
	transform: translateX(-50%);
	padding: 4px 8px;
	border-radius: 4px;
	width: fit-content;
	max-width: 300px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	font-weight: 500;
	z-index: 9999;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
	transition: all 0.2s ease;
}
.alert .icon {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 8px;
}

.alert-success {
	background-color: var(--c-success);
	color: var(--c-primary-dark);
}
.alert-danger {
	background-color: var(--c-danger);
	color: var(--c-light);
}
.alert-warning {
	background-color: var(--c-warning);
	color: var(--c-light);
}
