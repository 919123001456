@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

:root {
	--c-primary: #5865f2;
	--c-primary-light: #5865f21a;
	--c-primary-dark: #292841;
	--c-grey: #888888;
	--c-darkGrey: #222222;
	--c-lightGrey: #e0e0e0;
	--c-light: #f6f6f6;
	--c-success: #00e175;
	--c-danger: #ff2525;
	--c-warning: #ff993c;
	--c-disabled: #bbbbbb;
	--c-yellow: #ffbd00;
}
/* Global Styles */
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	scroll-behavior: smooth;
	font-family: 'Poppins', sans-serif;
}
body {
	background: var(--c-light);
	text-transform: lowercase;
	color: var(--c-primary-dark);
	-webkit-tap-highlight-color: transparent;
}

a {
	text-decoration: none;
	color: inherit;
}
/* width */
::-webkit-scrollbar {
	width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.link {
	cursor: pointer;
}
.c-primary {
	color: var(--c-primary);
}
.c-primary-light {
	color: var(--c-primary-light);
}
.c-primary-dark {
	color: var(--c-primary-dark);
}
.c-grey {
	color: var(--c-grey);
}
.c-darkGrey {
	color: var(--c-darkGrey);
}
.c-lightGrey {
	color: var(--c-lightGrey);
}
.c-light {
	color: var(--c-light);
}
.c-disabled {
	color: var(--c-disabled);
}
.c-success {
	color: var(--c-success);
}
.c-danger {
	color: var(--c-danger);
}
.c-warning {
	color: var(--c-warning);
}
.c-yellow {
	color: var(--c-yellow);
}

.rowCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}
.colCenter {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

/* Mobile Global CSS */
body {
	padding: 80px 20px 0px 20px;
	overflow-x: hidden;
}

.headingL {
	font-size: 48px;
	font-weight: 800;
}
.headingM {
	font-size: 32px;
	font-weight: 700;
}
.headingS {
	font-size: 24px;
	font-weight: 700;
}
.contentXL {
	font-size: 22px;
	font-weight: 600;
}
.contentL {
	font-size: 20px;
	font-weight: 600;
}
.content {
	font-size: 18px;
	font-weight: 500;
}
.contentM {
	font-size: 16px;
	font-weight: 600;
}
.contentS {
	font-size: 14px;
	font-weight: 600;
}
.contentXS {
	font-size: 12px;
	font-weight: 600;
}

/* Tablet CSS */
@media screen and (min-width: 800px) {
	body {
		padding: 80px 200px 0px 200px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1144px) {
	body {
		padding: 80px 300px 0px 300px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1340px) {
	body {
		padding: 80px 400px 0px 400px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1440px) {
	body {
		padding: 80px 500px 0px 500px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1640px) {
	body {
		padding: 80px 600px 0px 600px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1840px) {
	body {
		padding: 80px 700px 0px 700px;
	}
}
