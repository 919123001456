.pastEvents {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.pastEvents .pastEventsList {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 64px;
}
.pastEvents .pastEventsFilters,
.pastEvents .pastEventsFilters .filter {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.pastEvents .pastEventsFilters .filter .filterButton {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 24px;
	border-radius: 4px;
	background-color: var(--c-primary-light);
	color: var(--c-primary);
	font-size: 18px;
	font-weight: 600;
	cursor: pointer;
}
.pastEvents .pastEventsFilters .filter .filterOptions {
	position: absolute;
	top: 100%;
	display: flex;
	flex-direction: column;
	padding: 8px;
	width: 100%;
	margin-top: 8px;
	border-radius: 4px;
	background-color: var(--c-light);
	color: var(--c-primary);
	font-size: 18px;
	font-weight: 600;
	cursor: pointer;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	z-index: 99;
}
.pastEvents .pastEventsFilters .filter .filterOptions .filterOption {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 16px;
	border-radius: 4px;
	margin-bottom: 8px;
	transition: all 0.2s ease-in-out;
}
.pastEvents .pastEventsFilters .filter .filterOptions .filterOption:hover {
	background-color: var(--c-primary);
	color: var(--c-light);
}
