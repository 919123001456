.pageHeader {
	position: fixed;
	top: 0;
	left: 0;
	padding: 0 20px;
	width: 100vw;
	height: 72px;
	background-color: var(--c-primary-dark);
	color: var(--c-light);
	display: flex;
	justify-content: flex-start;
	align-items: center;
	z-index: 10;
}

.pageHeader .icon {
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
}

.pageHeader .title {
	width: 100%;
	margin-left: 8px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
/* Desktop CSS */
@media screen and (min-width: 800px) {
	.pageHeader {
		padding: 0 200px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1144px) {
	.pageHeader {
		padding: 0 300px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1340px) {
	.pageHeader {
		padding: 0 400px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1440px) {
	.pageHeader {
		padding: 0 500px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1640px) {
	.pageHeader {
		padding: 0 600px;
	}
}
/* Tablet CSS */
@media screen and (min-width: 1840px) {
	.pageHeader {
		padding: 0 700px;
	}
}
